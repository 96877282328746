.terms-standard-text{
    color: white;
}
.terms-and-conditions-box{
    padding: 30px;
}

@media(max-width: 500px){
    .how-to-buy-link{
        margin-left: 120px;
    }
}