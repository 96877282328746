.flight-slider-item {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    flex-shrink: 0;
    padding: 5px 10px;
    border: 1px solid rgb(192, 181, 255);
    border-radius: 8px;
    margin-right: 15px;

    p {
        display: flex;
        margin-bottom: 5px !important;
        align-items: center;
        color: white;

        span {
            width: 170px;
        }
    }
}

.flights-col {
    display: flex;
    flex-direction: column;
}

.flights-slider-container {
    display: flex;
    overflow: auto;
    align-items: flex-start;

    &::-webkit-scrollbar {
        display: none;
    }
}
.map-container{
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}