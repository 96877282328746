.data-table {
  width: 100%;
  max-width: 700px;
  margin: 30px auto 0px auto;
}

.items-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.items-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid rgb(223, 223, 223);
}
.items-list li span {
  color: white;
}

.left-side {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.title {
  width: 110px;
  color: white;
}

.copy {
  cursor: pointer;
}
.copy:active {
  color: greenyellow;
}/*# sourceMappingURL=SelectedAddress.module.css.map */