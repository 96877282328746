.services-items-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    span {
        margin-right: 20px;
        color: white;
        cursor: pointer;
    }
}

.banner-image-container{
    width: 100%;
    display: flex;
    margin-top: -16px;
    align-items: center;
    overflow: hidden;
    min-height: calc(100vh - 322px);
  }
  .content{
    min-height: calc(100vh - 338px);
    z-index: 1;
    width: 90%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .banner-image{
    position: absolute;
    width: 300px;
    height: fit-content;
  }
  @media(max-width: 900px){
    .banner-image{
      height: 150px;
    }
    .banner-image-container{
      margin-top: 0px !important;
    }
  }
  .container{
    position: relative;
    width: 90%;
    margin: auto;
    padding: 0px 0px 30px 0px;
    height: fit-content;
  }
  .title{
    font-size: 23px;
    color: #ffffff;
    font-weight: bold;
  }
  .restrict-text{
    color: #ffffff;
    font-size: 16px;
  }
  .restrict-title{
    color: #ffffff;
    margin-top: 0px !important;
    font-size: 23px;
    font-weight: bold;
  }
  .restrict-subtitle{
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }
  .subtitle{
    color: #ffffff;
    font-size: 18px;
  }
  .text{
    color: #ffffff;
    font-size: 16px;
  }
  .services li{
    line-height: 25px;
  }
  @media(max-width: 900px){
    .content{
      flex-direction: column;
    }
    .title, .restrict-title{
      font-size: 17px;
    }
    .subtitle, .restrict-subtitle{
      font-size: 15px;
    }
    .text, .restrict-text{
      font-size: 14px;
    }
    .banner-image-container{
      margin-top: 30px;
      justify-content: center;
    }
  }
  @media(max-width: 500px){
    .banner-image{
      width: 300px !important;
    }
    .content{
      align-items: center !important;
    }
  }