.admin-dashboard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    div{
        margin: 10px 0px;
    }
}
.box-title{
    width: fit-content;
}
.users-count-box{
    align-items: center;
    background: #f56954;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    width: 350px;
}

.active-parcels-count{
    align-items: center;
    background: #00a65a;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    width: 350px;
}

.box-title{
    color: white;
}

.active-flights-count{
    align-items: center;
    background: #00c0ef;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;
    width: 350px;
}

.count-number{
    font-size: 26px !important;
    color: white;
    font-weight: bold;
}

.admin-flights-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.textarea{
    height: 113px !important;
}
.submit-form-buttons{
    margin-top: 20px;
    justify-content: flex-end;
    display: flex;
}
.view-users-section-item{
    display: flex;
    align-items: flex-start;
}
.view-mode-option{
    color: white;
    font-weight: bold;
    width: 130px;
}
.view-mode-value{
    color: white;
    max-width: 170px;
    line-break: anywhere;
    margin-left: 8px;
}
.actions-modal-block{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0px;
}

.delivery-trackings{
    margin: 20px 0px;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span{
        flex-shrink: 1;
        color: white;
        font-weight: bold;
    }
}