.addresses-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 90vw;
  overflow: auto;
  height: 132px;
  flex-shrink: 0;
  margin: auto;
}
.addresses-container::-webkit-scrollbar {
  display: none;
}

.address-box {
  width: 80px;
  flex-shrink: 0;
  height: 60px;
  border: 3px solid white;
  cursor: pointer;
  margin: 0px 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.country-name {
  margin-top: 70px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0px 30px 0px;
}

.selected {
  border: 3px solid #022466;
}

@media (max-width: 500px) {
  .address-box {
    width: 70px;
    height: 50px;
    transition: 0.5s;
    margin: 0px 10px;
  }
  .address-box p {
    font-size: 12px;
  }
  .addresses-container {
    justify-content: space-between;
    width: 100%;
  }
}/*# sourceMappingURL=Addresses.module.css.map */