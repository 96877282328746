.logo{
  height: 100px;
}
.footer-container{
  margin-top: 50px;
  width: 100%;
  height: 260px;
  background: #17202b;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.footer-logo{
  font-size: 32px;
}
.social-pages{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}
.soc-item{
  display: flex;
  margin: 0px 25px;
  align-items: center;
}
.soc-item-value{
  margin-left: 20px;
  p{
    margin: 7px 0px;
  }
}
.soc-item-name{
  font-size: 13px;
  color: white;
}
.soc-item-title{
  font-size: 16px;
  font-weight: bold;
  color: white;
}
.hr{
  width: 100%;
  height: 2px;
  background: #3F3F3F;
}
@media(max-width: 542px){
  .soc-item{
    margin: 0px 10px;
  }
  .soc-item-value{
    margin-left: 5px;
  }
}