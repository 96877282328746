.flight-slider-item {
  display: flex;
  align-items: flex-start;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 0;
  padding: 5px 10px;
  border: 1px solid rgb(192, 181, 255);
  border-radius: 8px;
  margin-right: 15px;
}
.flight-slider-item p {
  display: flex;
  margin-bottom: 5px !important;
  align-items: center;
  color: white;
}
.flight-slider-item p span {
  width: 170px;
}

.flights-col {
  display: flex;
  flex-direction: column;
}

.flights-slider-container {
  display: flex;
  overflow: auto;
  align-items: flex-start;
}
.flights-slider-container::-webkit-scrollbar {
  display: none;
}

.map-container {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}/*# sourceMappingURL=FlightsSlider.css.map */