.sidebar-container {
    width: fit-content;
    padding-right: 30px;
}

.sidebar-content-list {
    display: flex;
    flex-direction: column;
    padding: 0px;
    list-style-type: none;

    .sidebar-item {
        margin-bottom: 10px;

        a {
            text-decoration: none;
            color: rgb(219, 219, 219);
        }
    }
}