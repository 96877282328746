.sidebar-container {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 30px;
}

.sidebar-content-list {
  display: flex;
  flex-direction: column;
  padding: 0px;
  list-style-type: none;
}
.sidebar-content-list .sidebar-item {
  margin-bottom: 10px;
}
.sidebar-content-list .sidebar-item a {
  text-decoration: none;
  color: rgb(219, 219, 219);
}/*# sourceMappingURL=Sidebar.css.map */