.header-container {
  background: #17202b;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-container .header-logo {
  height: 60px;
}
.header-container .header-content {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-sign-in-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 18px;
}

.header-sign-up-button {
  background: #2b5788;
  border: #2b5788;
  cursor: pointer;
  color: white;
  font-size: 18px;
  border-radius: 8px;
  padding: 3px 18px;
  margin-left: 20px;
}

.auth-modal-logo {
  width: 200px;
}

.auth-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.auth-header .auth-modal-title {
  color: white;
  font-weight: bold;
  margin-top: 20px;
}

.form-label {
  color: white;
}

.form-control {
  height: 35px !important;
}

.sign-in-button {
  width: 100%;
  height: 38px;
  border-radius: 8px;
  background: #2b5788;
  border: #2b5788;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.forgot-password-block {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  width: 100%;
}
.forgot-password-block button {
  color: white;
}

.forgot-password-text {
  color: white;
}

.forgot-password-button {
  background: none;
  border: none;
  color: rgb(4, 4, 226);
  font-size: 17px;
  text-decoration: underline;
}

.auth-redirect-to-sign-up {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.sign-up-role-switcher {
  background: rgba(59, 130, 246, 0.3);
  border-radius: 5px;
  padding: 5px;
}
.sign-up-role-switcher button {
  height: 40px;
  padding: 0px 20px;
  background: none;
  border: none;
  margin: 0px 3px;
  color: rgb(223, 223, 223);
}
.sign-up-role-switcher .active-tab {
  background: white;
  border-radius: 5px;
  color: blue;
}

.color-white {
  color: white !important;
}

.sign-up-button {
  background: rgba(59, 130, 246, 0.3);
  width: 100%;
  margin-top: 30px;
  height: 45px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
}

.terms-and-conditions-agreement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.terms-and-conditions-agreement label {
  color: white;
  margin: 0px 10px;
}

.redirect-to-log-in {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.redirect-to-log-in span {
  color: white;
}
.redirect-to-log-in button {
  background: none;
  border: none;
  margin-left: 10px;
  color: rgb(56, 56, 250);
  text-decoration: underline;
  font-size: 17px;
}

.select-language {
  border-radius: 50%;
  overflow: hidden;
  background: none;
  border: none;
}
.select-language img {
  width: 25px;
  height: 25px;
}

.header-user-dropdown-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6300;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: none;
}

.payment-buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0px;
}
.payment-buttons button {
  margin: 5px 0px;
}

.header-unauthorized-buttons {
  display: flex;
  align-items: center;
}

.header-user-dropdown-icon {
  margin-left: 10px;
}

.pay-all-container {
  display: flex;
  justify-content: center;
}

.user-dropdown-content {
  position: absolute;
  background: white;
  border: none;
  margin-left: -150px;
  padding: 8px 15px;
  z-index: 11111;
  box-shadow: 0 6px 17px -4px grey;
  border-radius: 8px;
}
.user-dropdown-content p {
  display: flex;
  margin-bottom: 3px;
  align-items: center;
  justify-content: space-between;
}

.edit-profile-button {
  font-size: 13px;
  background: #ff6300;
  border: #ff6300;
  color: white;
  padding: 5px 10px;
}

.log-out-button {
  font-size: 13px;
  background: #17202b;
  border: #17202b;
  color: white;
  padding: 5px 10px;
  margin-left: 25px;
  width: 80px;
}

.header-dropdown-buttons {
  display: flex;
  align-items: center;
}

.table-responsive {
  border-radius: 8px;
}

.empty-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-icon {
  display: none;
}

.no-background {
  background: none;
  border: none;
  padding: 0;
}

@media (max-width: 1024px) {
  .header-user-dropdown-icon {
    display: none;
    margin: auto;
  }
  .sign-up-role-switcher button {
    font-size: 14px;
  }
  .bar-icon {
    display: block;
    cursor: pointer;
    width: 35px;
  }
  .sign-up-role-switcher button {
    padding: 0px 10px;
    height: 25px;
  }
  .select-language {
    padding-right: 40px;
  }
  .hide-on-mobal {
    display: none;
  }
  .header-sign-in-button {
    font-size: 14px;
  }
  .header-sign-up-button {
    background: #2b5788;
    border: #2b5788;
    cursor: pointer;
    color: white;
    font-size: 14px;
    border-radius: 8px;
    padding: 3px 10px;
    margin-left: 10px;
  }
}
.comments-textarea {
  height: 90px !important;
}

@media (max-width: 762px) {
  .sign-up-role-switcher button {
    height: 42px;
  }
}/*# sourceMappingURL=Header.css.map */