.services-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.services-container a {
  width: calc(25% - 15px);
  max-width: 290px;
  text-decoration: none;
}

.service-box {
  border: 2px solid rgb(85, 113, 173);
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  height: 200px;
}
.service-box:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.services-icon {
  height: 100px;
  margin-bottom: 20px;
}

.section-titles {
  color: white;
  font-size: 22px;
  margin-top: 45px;
}/*# sourceMappingURL=Services.css.map */