.pages-container {
  width: 90%;
  margin: auto;
  margin-top: 35px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.sidebar-box {
  width: 231px;
}
.titles-box{
  margin-top: 50px;
}
.titles-box p, .titles-box strong{
   text-align: center;
   width: 100%;
   display: block;
   color: white;
   margin: 20px 0px;
}
.countries-container h4{
  color: white;
}
.pager {
  width: calc(100% - 231px);
  min-height: calc(100vh - 400px);
}

.none-button{
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
}

.market-image img{
  height: 100%;
}
.market-image{
  width: 150px;
  display: flex;
  align-items: center;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 12px;
  background-color: #dcdcdc;
  padding: 5px 15px;
  justify-content: center;
}
.markets-list{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.countries-container{
  margin-top: 30px;
}
.market-item{
  width: 150px;
  text-decoration: none;
}
.market-item p{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.calculator-value{
  margin-bottom: 0px;
  margin-top: 20px;
  color: white;
}

.calculator-container{
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.calculate-button{
  width: calc(100% - 20px);
  margin-top: 20px;
  height: 36px;
  border: none;
  border-radius: 8px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: gray;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.table-header-container {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pay-button {
  background: #17202b;
  border: 1px solid #17202b;
  color: white;
  height: 35px;
  width: 120px;
  border-radius: 8px;
}

.table-icon {
  width: 15px;
}

.search-keyword-container {
  display: flex;
  justify-content: center;
}

@media(max-width: 1024px) {
  .sidebar-box {
    display: none;
  }

  .pager {
    width: 100%;
  }
}

@media(max-width: 650px){
  .search-keyword-container input{
    max-width: 200px !important;
    font-size: 14px;
  }
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

.services-title {
  text-align: center;
}

@media(max-width: 800px) {
  .services-icon {
    height: 55px !important;
  }

  .service-box {
    height: 160px !important;
  }
}

.view-parcel-item{
  display: flex;
  align-items: center;
}
td, th{
  vertical-align: middle;
}
.view-parcel-modal{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

@media(max-width: 992px){
  .view-parcel-modal{
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .bottom{
    margin-top: 30px;
  }
  .view-parcel-item div p{
    width: fit-content !important;
  }
}

.upload-files-block{
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

@media(max-width: 563px){
  .table-header-container select, .table-header-container div, .table-header-container button{
    margin-bottom: 15px;
  }
}


.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 375px);
}

.page-404 h2 {
  color: white !important;
}

.flight-slider-item .flights-col .first-col {
  width: 130px;
  min-width: 130px;
}

.flight-slider-item .flights-col .sec-col {
  width: 110px;
  min-width: 110px;
}

.flight-slider-item {
  min-width: 390px !important;
}

@media(max-width: 630px) {
  .services-container {
    flex-wrap: wrap;
  }

  .services-title {
    font-size: 14px;
  }

  .services-container a {
    width: calc(50% - 10px) !important;
    margin-bottom: 10px;
  }
}

.aside-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px; /* Adjust the width as per your requirement */
  background-color: #051c2d !important;
  margin-top: 0px;
  margin-bottom: 0px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.aside-modal.show {
  transform: translateX(0);
}